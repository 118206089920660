import { ContentType, ProjectionType, AccessType, MediaClass } from '@/utils/enums';
import { iv } from '@/app.config';
import moment from 'moment';
const INTERACTIVE_PLAYER_EMBED_URL = iv.interactive_player_embed_url;

export const isContentFlat = content => {
  const { ref } = content;
  let isFlat = false;
  if ( ref.primaryFormat ) {
      isFlat = ref.primaryFormat.video?.projection === ProjectionType.FLAT;
  } else if ( ref.cameras?.length ) {
      isFlat = ref.cameras.some( ({ video }) => video?.projection === ProjectionType.FLAT );
  }
  return isFlat;
};

export const isContent360 = content => {
  const { ref } = content;
  let is360 = false;
  if ( ref.primaryFormat ) {
      is360 = ref.primaryFormat.video?.projection !== ProjectionType.FLAT;
  } else if ( ref.cameras?.length ) {
      is360 = ref.cameras.some( ({ video }) => video?.projection !== ProjectionType.FLAT );
  }
  return is360;
};

export const isContent360Video = content => (( content.type === ContentType.VOD || content.type === ContentType.STREAM ) && isContent360( content ) );

export const isContent2D = content => (( content.type === ContentType.VOD || content.type === ContentType.STREAM ) && isContentFlat( content ) );

export const isContentCollection = content => content.type === ContentType.COLLECTION;

export const getSceneMedia = scene => ( scene?.sequences[0]?.media );

export const getContentTitle = ( content, defaultTitle = '' ) => {
  if ( content.primaryLanguage )
      defaultTitle = content.primaryLanguage.title;
      else if( content.ref.title){
        //for ar 
        defaultTitle = content.ref.title;
      }

  return defaultTitle;
};

export function firstVideoInformation ( content, defaultValue ) {
  const { ref } = content;
  if ( ref?.primaryFormat ) {
      defaultValue = ref.primaryFormat?.video;
  } else if ( ref?.cameras ) {
      defaultValue = ref.cameras[0]?.video;
  } else if ( ref?.interactive ) {
      defaultValue = getSceneMedia( ref.interactive?.scenes[0]);
  }

  return defaultValue;
}

export const playerEmbedUrl = content => `${INTERACTIVE_PLAYER_EMBED_URL}${content.hash}`;

export const isBannerAd = content => content.ref && content.ref.ad && content.ref.ad.__typename === 'BannerAd';

export const isVideoOrPromotedAd = content => content.ref && content.ref.ad && ( content.ref.ad.__typename === 'PromotedAd' || content.ref.ad.__typename === 'VideoAd' );

export const isTypeInteractive = content => content.type === ContentType.INTERACTIVE;

export const isContentVideoOrLive = content => content.type === 'VOD' || content.type === 'INTERACTIVE' || content.type === 'STREAM';

export const SupportedLanguages = [
  'en-US',
  'en-GB',
  'zh-CN',
  'zh-TW',
  'zh-HK',
  'fr',
  'es',
  'de',
  'fi',
  'no',
  'nb',
  'sv',
  'da',
  'ro',
  'sk',
  'pl',
  'lb',
  'nl',
  'dz',
  'ms-MY'
];

export const EntertainmentCategoryList = [
  'Entertainment',
  'Sports',
  'Music',
  'Action',
  'Family',
  'Games',
  'Funny',
  'Political',
  'TV',
  'Movies',
  'News',
  'Drama',
  'Education',
  'Art',
  'Travel',
  'Technology'
];

export function getContentStreamCameras ( contentStream, defaultCameras = []) {
  return contentStream?.ref?.cameras || defaultCameras;
}

export function getContentStreamCamerasMediaMap ( contentVOD ) {
  return getContentStreamCameras( contentVOD ).reduce( ( map, camera, index ) => {
      map[camera?.media?.id || index] = {
          camera
      };
      return map;
  }, {});
}

export function getContentStreamBundleMediaUncoupled ( contentStream, media ) {
  const contentStreamCamerasMediaMap = getContentStreamCamerasMediaMap( contentStream );
  return media?.filter( mediaItem => mediaItem.mediaClass === MediaClass.LIVE
          && !contentStreamCamerasMediaMap[mediaItem.id]) || [];
}

export function accessTypeBasedOnAvailability ( availability, access, enums ) {
  if ( !availability ) {
      return enums ? AccessType.values : access;
  }

  const { start, end } = availability;
  const now = moment();

  if ( start && ( !end || Object.keys( end ).length === 0 ) ) {
      const differenceInMinutes = now.diff( start, 'minutes' );
      if ( differenceInMinutes >= 0 ) {
          return enums ? [ AccessType.PUBLIC ] : AccessType.PUBLIC;
      }
      return enums ? AccessType.values.filter( type => type !== AccessType.PUBLIC ) : access === AccessType.PUBLIC ? AccessType.PRIVATE : access;
  }
  if ( end && ( !start || Object.keys( start ).length === 0 ) ) {
    const differenceInMinutes = now.diff( end, 'minutes' );
    if ( differenceInMinutes < 0 ) {
        return enums ? [ AccessType.PUBLIC ] : AccessType.PUBLIC;
    }
    return enums ? AccessType.values.filter( type => type !== AccessType.PUBLIC ) : access === AccessType.PUBLIC ? AccessType.PRIVATE : access;
}

  if ( ( start && end ) || ( ( !start || Object.keys( start ).length === 0 ) && end ) ) {
      const differenceInMinutes = now.diff( end, 'minutes' );
      if ( differenceInMinutes >= 0 ) {
          return enums ? AccessType.values.filter( type => type !== AccessType.PUBLIC ) : access === AccessType.PUBLIC ? AccessType.PRIVATE : access;
      }
      if ( start ) {
          const startDifferenceInMinutes = now.diff( start, 'minutes' );
          if ( startDifferenceInMinutes < 0 ) {
              return enums ? AccessType.values.filter( type => type !== AccessType.PUBLIC ) : access === AccessType.PUBLIC ? AccessType.PRIVATE : access;
          }
      }
      return enums ? [ AccessType.PUBLIC ] : AccessType.PUBLIC;
  }

  return enums ? AccessType.values : access;
}

export function disabledAccessTypeBasedOnAvailability ( availability, access ) {
  const allowedAccessTypes = accessTypeBasedOnAvailability( availability, access, true );
  if ( !availability.end && ( availability.start === null || Object.keys( availability.start).length === 0 ) ) {
    return [];
  }
  return AccessType.values.filter( type => !allowedAccessTypes.includes( type ) );
}

export function disabledAccessTypeBasedOnAvailabilityWindow ( availability, startTime, endTime ) {
  const currentTime = moment().utc().format( 'YYYY-MM-DD' );
  if ( availability.end === null || availability.start === currentTime || availability.start <= currentTime && availability.end >= currentTime ) {
    if ( startTime < moment().format( 'HH:mm:ssZ' ) && endTime > moment().format( 'HH:mm:ssZ' ) ) {
     return [ 'TESTING', 'PRIVATE'];
    } 
  }
  return ( startTime === null || endTime === null || availability.start === null ) ? [] : ['PUBLIC'];

}

export function getContentVODFormats ( contentVOD, defaultFormats = []) {
  return contentVOD?.ref?.formats || defaultFormats;
}

export function getContentVODFormatsMediaMap ( contentVOD ) {
  return getContentVODFormats( contentVOD ).reduce( ( map, format, index ) => {
      map[format.media?.id || index] = {
          format
      };

      return map;
  }, {});
} 
export function getBundleMediaItems ( bundle, defaultMediaItems = []) {
  return bundle?.media || defaultMediaItems;
} 
export function getContentVODBundleMediaUncoupled ( contentVOD, bundle ) {
  const contentVODFormatsMediaMap = getContentVODFormatsMediaMap( contentVOD );

  return getBundleMediaItems( bundle )
      .filter( mediaItem => mediaItem.mediaClass === MediaClass.VIDEO
          && !contentVODFormatsMediaMap[mediaItem.id]);
}

export const  sortWithPrimary = formats => {
  const sorted = dateSort( formats );
  const indexOfPrimary = sorted.findIndex( f => f.isPrimary );
  const primaryFormat = sorted[indexOfPrimary];

  sorted.splice( indexOfPrimary, 1 );

  return [ primaryFormat, ...sorted ];
};

export function dateSort ( arr ) {
  return [ ...arr ].sort( ( a, b ) => new Date( a.createdAt ).getTime() > new Date( b.createdAt ).getTime() ? -1 : 1);
}
